import React, { ReactElement } from 'react'
import { NextPageWithLayout } from './_app'
import Layout from '../components/layout'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Component404 from '../components/404/Component404'

const Custom404: NextPageWithLayout = () => {
  return <Component404 />
}

Custom404.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

type Props = {
  locale: string
}

export async function getStaticProps({ locale }: Props) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default Custom404
