import Image from 'next/image'
import Icon404 from '../../public/static/404illustration.png'
import React from 'react'
import Link from 'next/link'
import { homeUrl } from '../../common/urls/homeUrl'

export default function Component404() {
  return (
    <div className='h-100v container mx-auto py-16 px-4 md:px-0'>
      <h1 className={'text-6xl font-fjalla font-normal text-center text-white'}>
        OOOPS,
      </h1>

      <div className={'relative'}>
        <div
          style={{
            position: 'absolute',
            top: 10,
            left: '50%',
            width: '80%',
            height: '80%',
            transform: 'translate(-50%, 0%)',
            zIndex: 0,
            background:
              'radial-gradient(50% 50% at 50% 50%, #506080 0%, rgba(5, 6, 8, 0.00) 100%)',
          }}
        ></div>

        <div className={'flex flex-col w-full items-center mt-2 mx-auto'}>
          <p className={'text-xl-normal text-white text-center mt-2'}>
            Something went wrong. Page not found.
          </p>

          <div className={'relative w-full md:w-[576px] h-[360px] mt-8'}>
            <Image
              src={Icon404}
              className={'object-contain'}
              alt={'BackGround'}
              fill={true}
            />
          </div>

          <Link
            href={homeUrl}
            className={'btn-v2-primary mt-20 cursor-pointer w-full md:w-108'}
          >
            Back to homepage
          </Link>
        </div>
      </div>
    </div>
  )
}
